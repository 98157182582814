<template>
    <div class="alert-time-selector">
        <p class="alert-time-selector-title">监测时段</p>
        <el-time-select class="alert-time-selector-content" placeholder="起始时间" v-model="startTime" :picker-options="{
            start: '00:00',
            end: '23:45',
            step: '00:15',
        }" :append-to-body="false" :popper-options="{ positionFixed: true }">
        </el-time-select>
        <p class="alert-time-selector-span">-</p>
        <el-time-select class="alert-time-selector-content" placeholder="结束时间" v-model="endTime" :picker-options="{
            start: '00:00',
            end: '23:45',
            step: '00:15',
        }" :append-to-body="false" :popper-options="{ positionFixed: true }">
        </el-time-select>
    </div>
</template>

<script>
export default {
    model: {
        prop: "initDuration",
        event: "onInitDurationChanged"
    },

    props: {
        initDuration: {
            startTime: String,
            endTime: String
        }
    },

    data() {
        return {
            startTime: undefined,
            endTime: undefined
        }
    },

    watch: {
        initDuration(value) {
            this.startTime = value.startTime
            this.endTime = value.endTime
        },

        startTime(value) {
            this.$emit("onInitDurationChanged", {
                startTime: value,
                endTime: this.endTime
            })
        },

        endTime(value) {
            this.$emit("onInitDurationChanged", {
                startTime: this.startTime,
                endTime: value
            })
        } 
    }
}
</script>

<style lang="scss" scoped>


.alert-time-selector {
    background-color: #F6F6F6;
    border-radius: rem(6);
    box-sizing: border-box;
    width: rem(260);
    height: rem(44);
    padding-left: rem(10);

    display: flex;
    align-items: center;

    &-title {
        color: #444C72;
        font-size: rem(14);
        margin-right: rem(10);
    }

    &-span {
        color: #444C72;
        font-size: rem(14);
    }

    &-content {
        margin-top: rem(2);
        width: rem(80);

        /deep/.el-date-editor--time-select {
            width: rem(50);
        }

        /deep/.el-input__inner {
            background-color: #f7f7f7;
            width: 100%;
            border-color: transparent !important;
            font-size: rem(14);
            padding: 0px;
            text-align: center
        }

        /deep/.el-input__suffix {
            display: none
        }

        /deep/.el-input__icon{
            color: transparent
        }

        /deep/.el-picker-panel time-select el-popper {
            @include z-index-max;
        }

        /deep/.time-select-item {
            font-size: rem(14);
            box-sizing: border-box;
            text-align: center;
        }

        /deep/.time-select-item.selected:not(.disabled) {
            color: #325FAC;
        }
    }
}
</style>
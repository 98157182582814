<template>
  <div class="keyword-search">
    <input
      class="keyword-search-input"
      type="text"
      placeholder="请输入姓名或设备SN"
      v-model="keyword"
      @keyup.enter="onSearchClick"
    />
    <button
      class="keyword-search-search"
      :style="{
        backgroundImage: `url(${require('@imgs/search.png')})`,
      }"
      @click="onSearchClick"
    ></button>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      keyword: "",
    };
  },

  methods: {
    onSearchClick: function () {
      this.$emit("onSearchClick", this.keyword);
    },
  },
};
</script>

<style lang="scss" scoped>


.keyword-search {
  background-color: white;
  border: 1px solid #d9d9d9;
  border-radius: rem(4);
  box-sizing: border-box;
  padding-left: rem(12);
  padding-right: rem(14);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-input {
    color: black;
    opacity: 0.65;
    font-size: rem(14);
    border: none; // 去除未选中状态边框
    outline: none; // 去除选中状态边框
  }

  &-input::-webkit-input-placeholder {
    color: black;
    opacity: 0.25;
    font-size: rem(14);
  }

  &-search {
    background-size: rem(14) rem(14);
    background-repeat: no-repeat;
    width: rem(14);
    height: rem(14);
  }
}
</style>
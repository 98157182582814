<template>
    <div class="panel-monitor-info">
        <div class="panel-monitor-info-top">
            <p class="panel-monitor-info-top-title">监测信息</p>
            <p class="panel-monitor-info-top-update-time">更新于：{{ placeholderText(model.updateTime) }}</p>
        </div>
        <div class="panel-monitor-info-middle">
            <icon-unit :iconURL="heartRateIcon" :iconWidth="24" :value="heartrateText" unit="bpm"></icon-unit>
            <icon-unit :iconURL="breathRateIcon" :iconWidth="24" :value="breathrateText" unit="bpm"></icon-unit>
            <icon-unit :iconURL="bloodOxygenIcon" :iconWidth="19" :value="bloodoxygenText" unit="%"></icon-unit>
        </div>
        <div class="panel-monitor-info-bottom">
            <key-value-unit title="监测时段" :value="placeholderText(model.monitor)"></key-value-unit>
            <key-value-unit title="工作模式" :value="placeholderText(monitorModeStr)"></key-value-unit>
        </div>
    </div>
</template>

<script>
import IconUnit from "@c/monitor-center/device-detail-panel/icon-unit.vue";
import KeyValueUnit from "@c/monitor-center/device-detail-panel/key-value-unit.vue";
import { findKey } from "@js/object-tool.js";
export default {
    components: {
        IconUnit,
        KeyValueUnit,
    },

    props: {
        model: Object
    },

    data() {
        return {
            heartRateIcon: require("@imgs/monitor-center-heart-rate.png"),
            breathRateIcon: require("@imgs/monitor-center-breath-rate.png"),
            bloodOxygenIcon: require("@imgs/monitor-center-blood-oxygen.png"),
        }
    },

    computed: {
        monitorModeStr() {
            return findKey(DEVICE_MONITOR_MODE, this.model.modeType)
        },
        heartrateText() {
            if (this.model.online) {
                if (this.model.heartRate < 1) {
                    return "心率：-";
                } else {
                    return "心率：" + placeholderText(this.model.heartRate);
                }
            } else {
                return "心率：-";
            }

        },

        bloodoxygenText() {
            if (this.model.online) {
                if (this.model.bloodOxygen < 1) {
                    return "血氧：-";
                } else {
                    if (this.model.connectStatus == RING_CONNECT_STATUS_CODE.已连接) {
                        return "血氧：" + placeholderText(this.model.bloodOxygen);
                    }else {
                        return "血氧：-";
                    }
                    
                }
            } else {
                return "血氧：-";
            }

        },

        breathrateText() {
            if (this.model.online) {
                if (this.model.breathRate < 1) {
                    return "呼吸：-";
                } else {
                    return "呼吸：" + placeholderText(this.model.breathRate);
                }
            } else {
                return "呼吸：-";
            }

        },
    },

    methods: {
        placeholderText(text, unit) {
            return placeholderText(text, unit)
        }
    }
}
</script>

<style lang="scss" scoped>
.panel-monitor-info {
    margin-top: rem(20);

    &-top {
        display: flex;
        justify-content: space-between;

        &-title {
            font-size: rem(16);
            font-weight: bold;
        }

        &-update-time {
            color: #888888;
            font-size: rem(12);
        }

    }

    &-middle {
        height: rem(64);
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
</style>
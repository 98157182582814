import { DateTool } from "@js/date-tool.js";

export function computeDurationStr(monitorDuration) {
    const startStr = `2023-06-19 ${monitorDuration.startTime}`
    const startTimeStamp = DateTool.strToMillisecondTimestamp(startStr)
    const endStr = `2023-06-19 ${monitorDuration.endTime}`
    const endTimeStamp = DateTool.strToMillisecondTimestamp(endStr)
    const duration = (endTimeStamp > startTimeStamp) ? (endTimeStamp - startTimeStamp) : (endTimeStamp - startTimeStamp + 24 * 3600000)
    if (duration < 4 * 3600000) {
        throw ("监测时长不能低于4小时!")
    } else if (duration > 12 * 3600000) {
        throw ("监测时长不能超过12小时!")
    } else {
        return `${monitorDuration.startTime}-${monitorDuration.endTime}`
    }
}
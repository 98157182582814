<template>
    <div class="icon-unit">
        <img :src="iconURL" :style="{ width: `${iconWidth}px`}">
        <p class="icon-unit-value">{{ placeholderText(value) }}</p>
        <p class="icon-unit-unit">{{ unit }}</p>
    </div>
</template>

<script>
export default {
    props: {
        iconURL: String,
        iconWidth: Number,
        value: String,
        unit: String
    },

    methods: {
        placeholderText(text) {
            return placeholderText(text)
        }
    }
}
</script>

<style lang="scss" scoped>


.icon-unit{
    display: flex;
    align-items: center;

    &-value{
        color: #352641;
        font-size: rem(16);
        font-weight: bold;
        margin-left: rem(3);
    }

    &-unit{
        color: #888888;
        font-size: rem(12);
        margin-left: rem(6);
    }
}

</style>
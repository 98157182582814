<template>
    <div class="panel-monitor">
        <p class="panel-monitor-title">监护仪</p>
        <div class="panel-monitor-content">
            <div class="panel-monitor-content-network">
                <key-value-unit title="网络状态" :value="placeholderText(netStatusStr)"></key-value-unit>
                <img class="panel-monitor-content-network-img" :src="netIconURL" v-if="model.deviceConnectStatus != 1"/>
            </div>
            <key-value-unit title="固件版本" :value="placeholderText(model.versionNo)"></key-value-unit>
            <key-value-unit title="监测状态" :value="placeholderText(monitorStatusStr)"></key-value-unit>
            <key-value-unit title="设备升级" :value="placeholderText(autoUpgradeStatusStr)"></key-value-unit>
        </div>

    </div>
</template>

<script>
import KeyValueUnit from "@c/monitor-center/device-detail-panel/key-value-unit.vue";
import { findKey } from "@js/object-tool.js";
export default {
    components: {
        KeyValueUnit
    },
    // successIcon: require("@imgs/success.png"),
    props: {
        model: Object
    },

    computed: {
        netIconURL() {
            if (this.model.online) {
                const networkType = findKey(MONITOR_NETWORK_TYPE, this.model.networkType);
                if (!networkType) { return null }
                const sinnalValue = networkType == "移动信号" ? this.model.mobileRssi : this.model.wifiRssi;
                if (!sinnalValue) { return null }
                let iconValue = "";
                switch (true) {
                    case sinnalValue < -90:
                        iconValue = "0";
                        break;
                    case sinnalValue >= -90 && sinnalValue < -80:
                        iconValue = "1";
                        break;
                    case sinnalValue >= -80 && sinnalValue < -70:
                        iconValue = "2";
                        break;
                    case sinnalValue >= -70 && sinnalValue < -60:
                        iconValue = "3";
                        break;
                    default:
                        iconValue = "4";
                        break;
                }
                switch (networkType) {
                    case "移动信号":
                        return require(`@imgs/mobile-${iconValue}.png`);
                    case "wifi信号":
                        return require(`@imgs/wifi-${iconValue}.png`);
                    case "mifi信号":
                        return require(`@imgs/mifi.png`);
                    default:
                        return "";
                }
            } else {
                return null;
            }

        },
        netStatusStr() {
            const deviceConnectStatus = this.model.deviceConnectStatus
            if (deviceConnectStatus) {
                return deviceConnectStatus == MONITOR_WORK_STATUS_CODE.未连接 ? '未连接' : '已连接'
            } else {
                return null
            }
        },

        monitorStatusStr() {
            const deviceConnectStatus = this.model.deviceConnectStatus
            if (deviceConnectStatus) {
                return deviceConnectStatus == MONITOR_WORK_STATUS_CODE.未连接 ? null : findKey(MONITOR_WORK_STATUS_CODE, deviceConnectStatus)
            } else {
                return null
            }
        },

        autoUpgradeStatusStr() {
            return findKey(MONITOR_AUTO_UPGRADE_STATUS, this.model.deviceUpgrade)
        }
    },

    methods: {
        placeholderText(text, unit) {
            return placeholderText(text, unit)
        }
    }
}
</script>

<style lang="scss" scoped>
.panel-monitor {
    margin-top: rem(20);
    box-sizing: border-box;
    padding-bottom: rem(20);
    border-bottom: rem(1) dashed #E6E4E9;

    &-title {
        font-size: rem(14);
        line-height: rem(20);
    }

    &-content {
        display: flex;
        flex-wrap: wrap;

        &-network {
            width: 50%;
            display: flex;

            &-img {
                width: rem(17);
                height: rem(13);
                margin-top: rem(10);

            }
        }

        .key-value-unit {
            min-width: 50%;
            margin-top: rem(10);
        }
    }
}
</style>
<template>
    <div class="panel-ring">
        <div class="panel-ring-top">
            <p class="panel-ring-top-title">智能指环</p>
            <key-value-unit class="panel-ring-top-sn" title="SN" :value="placeholderText(model.ringSn)"></key-value-unit>
        </div>
        <div class="panel-ring-content">
            <key-value-unit title="连接状态" :value="placeholderText(connectStatusStr)"></key-value-unit>
            <key-value-unit title="固件版本" :value="placeholderText(model.swVersion)"></key-value-unit>
            <key-value-unit title="监测状态" :value="placeholderText(monitorStatusStr)"></key-value-unit>
            <key-value-unit title="指环升级" :value="placeholderText(autoUpdateStatusStr)"></key-value-unit>
            <key-value-unit title="戒指数据" :value="placeholderText(dataStatusStr)"></key-value-unit>
            <key-value-unit title="戒指电量" :value="batteryStr">
                <template v-slot>
                    <p v-if="model.powerStatus == RING_POWER_STATUS_CODE.充电中 || model.powerStatus == RING_POWER_STATUS_CODE.充满可工作"
                        class="panel-ring-content-in-charge">充电中</p>
                </template>
            </key-value-unit>
        </div>
    </div>
</template>

<script>
import KeyValueUnit from "@c/monitor-center/device-detail-panel/key-value-unit.vue";
export default {
    components: {
        KeyValueUnit
    },

    props: {
        model: Object
    },

    data() {
        return {
            RING_CONNECT_STATUS_CODE: RING_CONNECT_STATUS_CODE,
            RING_POWER_STATUS_CODE: RING_POWER_STATUS_CODE
        }
    },

    computed: {
        batteryStr() {
            const connectStatus = this.model.connectStatus
            const deviceConnectStatus = this.model.deviceConnectStatus
            if (this.model.ringSn == null || this.model.ringSn == "") {
                return "-"
            } else {
                if (deviceConnectStatus == MONITOR_WORK_STATUS_CODE.未连接) {
                    return "-"
                } else {
                    if (connectStatus == RING_CONNECT_STATUS_CODE.已连接) {
                        if (this.model.battery < 0) {
                            return "-"
                        } else {
                            return placeholderText(this.model.battery, '%')
                        }
                    }else {
                        return "-"
                    }

                }

            }

        },
        connectStatusStr() {
            const connectStatus = this.model.connectStatus
            const deviceConnectStatus = this.model.deviceConnectStatus

            if (this.model.ringSn == null || this.model.ringSn == "") {
                return null
            } else {
                if (deviceConnectStatus == MONITOR_WORK_STATUS_CODE.未连接) {
                    return null
                } else {
                    if (connectStatus) {
                        if (connectStatus == RING_CONNECT_STATUS_CODE.已连接) {
                            return "已连接";
                        } else {
                            return "未连接";
                        }
                        // return connectStatus == RING_CONNECT_STATUS_CODE.已连接 ? '已连接' : '未连接'
                    } else {
                        return null
                    }
                }

            }

        },

        monitorStatusStr() {
            const connectStatus = this.model.connectStatus
            if (this.model.ringSn == null || this.model.ringSn == "") {
                return null
            } else {
                if (connectStatus == RING_CONNECT_STATUS_CODE.已连接 && this.model.ringStatus == RING_MONITOR_STATUS_CODE.已开启戒指监测) {
                    return "开启"
                } else {
                    return null
                }
            }

        },

        autoUpdateStatusStr() {
            // findKey(RING_AUTO_UPGRADE_STATUS, this.model.ringUpgrade)
            if (this.model.ringSn == null || this.model.ringSn == "") {
                return null
            } else {
                if (this.model.ringUpgrade == 1) {
                    return "开启"
                } else {
                    return "关闭"
                }
            }

        },

        dataStatusStr() {
            if (this.model.ringSn == null || this.model.ringSn == "") {
                return null
            } else {
                switch (this.model.ringStatus) {
                    case RING_MONITOR_STATUS_CODE.待收取血氧数据:
                        return "未收取血氧"
                    case RING_MONITOR_STATUS_CODE.戒指数据传输完成:
                        return "上传完成"
                    default:
                        return null
                }
            }

        }
    },

    methods: {
        placeholderText(text, unit) {
            return placeholderText(text, unit)
        }
    }
}
</script>

<style lang="scss" scoped>
.panel-ring {
    margin-top: rem(20);

    &-top {
        display: flex;
        align-items: center;

        &-title {
            font-size: rem(14);
            line-height: rem(20);
        }

        &-sn {
            margin-left: rem(74);
        }
    }

    &-content {
        display: flex;
        flex-wrap: wrap;

        .key-value-unit {
            margin-top: rem(10);
            width: 50%;
        }

        &-in-charge {
            color: #6CC291;
            font-size: rem(12);
            margin-left: rem(10);
        }
    }
}
</style>
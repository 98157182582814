<template>
    <div class="monitor-center-alert" @click="onMaskClick">
        <div class="monitor-center-alert-card" @click="onCardClick($event)">
            <slot name="top"></slot>
            <div class="monitor-center-alert-card-button">
                <button class="monitor-center-alert-card-button-item left" @click="onLeftButtonClick">{{
                    leftButtonTitle }}</button>
                <button class="monitor-center-alert-card-button-item right" @click="onRightButtonClick">{{ rightButtonTitle
                }}</button>
            </div>
            <slot name="bottom"></slot>
        </div>
    </div>
</template>
  
<script>
export default {
    model: {
        prop: "isShowAlert",
        event: "onIsShowAlertChanged"
    },

    props: {
        isShowAlert: Boolean,
        leftButtonTitle: {
            type: String,
            default: "取消"
        },
        rightButtonTitle: {
            type: String,
            default: "确定"
        }
    },

    methods: {
        onCardClick(event) {
            event.stopPropagation(); //停止穿透
        },

        onMaskClick() {
            this.$emit("onIsShowAlertChanged", false);
        },

        onLeftButtonClick() {
            this.$emit("onIsShowAlertChanged", false)
        },

        onRightButtonClick() {
            this.$emit("onRightButtonClick")
        }
    },
};
</script>
  
<style lang="scss" scoped>


.monitor-center-alert {
    @include z-index-max;
    background-color: rgba($color: #666666, $alpha: 0.2);
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &-card {
        background-color: white;
        box-sizing: border-box;
        width: rem(335);
        padding: rem(20) rem(38) rem(40);
        border-radius: 10px;
        box-shadow: rem(5) rem(5) rem(30) rem(1) rgba(black, 0.02);

        &-button {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &-item {
                font-size: rem(12);
                border-radius: rem(5);
                height: rem(36);
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

.left {
    background-color: #F7F7F7;
    color: #888888;
    width: rem(72);
}

.right {
    background-color: #325FAC;
    color: white;
    width: rem(167);
}
</style>

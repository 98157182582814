<template>
    <div class="alert-paging-selector">
        <p class="alert-paging-selector-title">{{ title }}</p>
        <el-select class="alert-paging-selector-content" ref="select" filterable remote reserve-keyword placeholder="请选择"
            v-model="value" :remote-method="onFilter" :popper-append-to-body="false">
            <el-option v-for="item in dataArray" :key="item.id" :value="item.id" :label="item.trueName"></el-option>
        </el-select>
    </div>
</template>

<script>
export default {
    model: {
        prop: "initValue",
        event: "onInitValueChanged"
    },

    props: {
        title: String,
        hospitalID: Number,
        initValue: Number,
    },

    data() {
        return {
            loading: false,
            currentPage: 1,
            keyWord: "",
            pageSize: 10,
            dataArray: [],
            isLastPage: false,
            value: undefined
        }
    },

    mounted() {
        this.$refs.select.$refs.scrollbar.$refs.wrap.addEventListener('scroll', this.onScroll)
        this.getUserList()
    },

    methods: {
        onScroll() {
            if (this.isLastPage) return
            let e = this.$refs.select.$refs.scrollbar.$refs.wrap
            let isBottom = e.scrollHeight - e.scrollTop <= e.clientHeight
            if (isBottom) {
                this.loadMore()
            }
        },

        onFilter(value) {
            this.keyWord = value
            this.currentPage = 1
            this.dataArray = []
            this.getUserList()

        },

        loadMore() {
            if (!this.loading) {
                this.currentPage += 1
                this.getUserList()
            }
        },



        async getUserList() {
            try {
                this.loading = true;
                const params = {
                    "keyWord": this.keyWord,
                    "pageNum": this.currentPage,
                    "hospitalId": this.hospitalID,
                    "pageSize": this.pageSize
                }
                const response = await this.$api.getUserPageList(params)
                this.loading = false;
                this.dataArray = this.dataArray.concat(response.list);
                this.isLastPage = response.boolLastPage
            } catch (error) {
                this.loading = false;
                this.$toast.showRed(error);
            }
        },

    },

    watch: {
        initValue(value) {
            this.value = value
        },

        value(value) {
            this.$emit("onInitValueChanged", value)
        }
    }
}
</script>

<style lang="scss" scoped>


.alert-paging-selector {
    background-color: #F6F6F6;
    box-sizing: border-box;
    width: rem(260);
    height: rem(44);
    padding-left: rem(10);
    border-radius: rem(6);

    display: flex;
    align-items: center;

    &-title {
        color: #444C72;
        font-size: rem(14);
        margin-right: rem(15);
    }

    &-content {
        margin-top: rem(2);
        width: rem(172);

        /deep/.el-input__inner {
            background-color: #F6F6F6;
            border-color: transparent !important;
            width: 100%;
        }

        /deep/.el-select__caret {
            color: #444C72
        }

        /deep/.el-select-dropdown el-popper {
            @include z-index-max;
        }

        /deep/.el-select-dropdown__item {
            font-size: rem(14);
            box-sizing: border-box;
            padding-left: rem(20);
        }

        /deep/.el-select-dropdown__item.selected {
            color: #325FAC;
        }
    }
}
</style>
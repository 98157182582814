<template>
  <div class="cell" @click="onCellClick">
    <div class="cell-device">
      <div class="cell-device-top">
        <div class="cell-device-top-left">
          <p v-if="model.trueName" class="cell-device-top-left-trueName">{{ model.trueName }}</p>
          <div v-else>
            <img v-if="model.deviceStatus == DEVICE_BINDING_STATUS_CODE.闲置" class="cell-device-top-left-icon"
              src="@imgs/add.png" />
            <img v-else class="cell-device-top-left-icon" src="@imgs/forbidden.png" />
          </div>
        </div>
        <img v-if="netIconURL" class="cell-device-top-signal" :src="netIconURL" />
      </div>
      <p class="cell-device-sn">{{ model.sn }}</p>
      <div class="cell-device-data">
        <p class="cell-device-data-unit">{{ heartrateText }}</p>
        <p class="cell-device-data-unit">{{ breathrateText }}</p>
        <p class="cell-device-data-unit">{{ bloodoxygenText }}</p>
        <p class="cell-device-data-unit">{{ tempetatureText }}</p>
      </div>
      <div class="cell-device-bottom">
        <div class="cell-device-bottom-left">
          <img v-if="ringBatteryIconSrc" class="cell-device-bottom-left-battery-icon" :src="ringBatteryIconSrc" />
          <img v-if="isChildMode" class="cell-device-bottom-left-child-icon" src="@imgs/child.png" />
          <img v-if="hasWarning" class="cell-device-bottom-left-warn-icon" src="@imgs/warning.png" />
        </div>
        <p class="cell-device-bottom-stop-time">{{ monitorStopTimeStr }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTool } from "@js/date-tool.js";
export default {
  props: {
    model: Object,
  },

  data() {
    return {
      DEVICE_BINDING_STATUS_CODE: DEVICE_BINDING_STATUS_CODE
    }
  },

  computed: {
    netIconURL() {
      if (this.model.online) {
        const networkType = findKey(MONITOR_NETWORK_TYPE, this.model.networkType);
        if (!networkType) { return null }
        const sinnalValue = networkType == "移动信号" ? this.model.mobileRssi : this.model.wifiRssi;
        if (!sinnalValue) { return null }
        let iconValue = "";
        switch (true) {
          case sinnalValue < -90:
            iconValue = "0";
            break;
          case sinnalValue >= -90 && sinnalValue < -80:
            iconValue = "1";
            break;
          case sinnalValue >= -80 && sinnalValue < -70:
            iconValue = "2";
            break;
          case sinnalValue >= -70 && sinnalValue < -60:
            iconValue = "3";
            break;
          default:
            iconValue = "4";
            break;
        }
        switch (networkType) {
          case "移动信号":
            return require(`@imgs/mobile-${iconValue}.png`);
          case "wifi信号":
            return require(`@imgs/wifi-${iconValue}.png`);
          case "mifi信号":
            return require(`@imgs/mifi.png`);
          default:
            return "";
        }
      } else {
        return null;
      }

    },

    heartrateText() {
      if (this.model.online) {
        if (this.model.heartRate < 1) {
          return "心率：-";
        } else {
          return "心率：" + placeholderText(this.model.heartRate);
        }
      } else {
        return "心率：-";
      }

    },

    bloodoxygenText() {
      if (this.model.online) {
        if (this.model.connectStatus == RING_CONNECT_STATUS_CODE.已连接) {
          if (this.model.bloodOxygen < 1) {
            return "血氧：-";
          } else {
            return "血氧：" + placeholderText(this.model.bloodOxygen, "%");
          }
        } else {
          return "血氧：-";
        }
      } else {
        return "血氧：-";
      }

    },

    breathrateText() {
      if (this.model.online) {
        if (this.model.breathRate < 1) {
          return "呼吸：-";
        } else {
          return "呼吸：" + placeholderText(this.model.breathRate);
        }
      } else {
        return "呼吸：-";
      }

    },

    tempetatureText() {
      if (this.model.online) {
        if (this.model.tempeature < 1) {
          return "室温：-";
        } else {
          return "室温：" + placeholderText(this.model.tempeature, "℃");
        }
      } else {
        return "室温：-";
      }

    },

    isRingInCharge() {
      return (
        this.model.powerStatus == RING_POWER_STATUS_CODE.充电中 ||
        this.model.powerStatus == RING_POWER_STATUS_CODE.充满可工作
      );
    },

    ringBatteryIconSrc() {
      if (this.model.online) {
        if (this.isRingInCharge) {
          return require("@imgs/charge.gif");
        } else {
          const battery = this.model.battery;
          switch (true) {
            case battery > 0 && battery <= 20:
              return require("@imgs/battery-20.png");
            case battery > 20 && battery <= 40:
              return require("@imgs/battery-40.png");
            case battery > 40 && battery <= 60:
              return require("@imgs/battery-60.png");
            case battery > 60 && battery <= 80:
              return require("@imgs/battery-80.png");
            case battery > 80:
              return require("@imgs/battery-100.png");
            default:
              return null
          }
        }
      } else {
        return null;
      }

    },

    isChildMode() {
      return this.model.modeType == DEVICE_MONITOR_MODE.儿童模式
    },

    hasWarning() {
      return this.model.warn == 1
    },

    monitorStopTimeStr() {
      const stopTimeDate = DateTool.strToDate(this.model.endTime)
      const stopTimeStr = DateTool.formatDate(stopTimeDate, "M/D h:m")
      return placeholderText(stopTimeStr)

    }

  },

  methods: {
    onCellClick() {
      this.$emit("onCellClick", this.model)
    }
  }

};
</script>

<style lang="scss" scoped>
.cell {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: rem(20);

  &-device {

    &-top {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-left {
        &-trueName {
          color: #352641;
          font-size: 16px;
          font-weight: bold;
          line-height: rem(22);
        }

        &-icon {
          width: rem(22);
          height: rem(22);
        }
      }

      &-signal {
        width: rem(22);
      }

    }

    &-sn {
      color: #352641;
      font-size: rem(12);
      line-height: rem(17);
      margin-top: rem(4);
    }

    &-data {
      color: #7C819C;
      font-size: rem(10);
      line-height: rem(14);
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      &-unit {
        width: 50%;
        margin-top: rem(10);
        display: flex;
        align-items: center;
      }
    }

    &-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: rem(12);

      &-left {
        display: flex;

        &-battery-icon {
          width: rem(22);
          height: rem(11);
        }

        &-child-icon {
          width: rem(14);
          height: rem(11);
          margin-left: rem(5);
        }

        &-warn-icon {
          width: rem(13);
          height: rem(11);
          margin-left: rem(5);
        }
      }

      &-stop-time {
        color: #7C819C;
        font-size: rem(12);
      }
    }
  }
}
</style>
<template>
    <div class="panel-user">
        <div class="panel-user-left">
            <div class="panel-user-left-info">
                <div v-if="model.trueName" class="panel-user-left-info-user">
                    <p class="panel-user-left-info-user-name">{{
                        placeholderText(model.trueName) }}
                    </p>
                    <p class="panel-user-left-info-user-age">{{
                        placeholderText(model.age, "岁")
                    }}</p>
                    <p class="panel-user-left-info-user-device-status">
                        {{ placeholderText(deviceStatusStr) }}</p>
                </div>
                <p v-else class="panel-user-left-info-device-status">{{
                    placeholderText(deviceStatusStr) }}</p>
            </div>
            <p class="panel-user-left-company">{{ placeholderText(model.hospitalName) }}</p>
        </div>
        <button class="panel-user-button" @click="onUserButtonClick">{{ model.trueName ?
            '解绑用户' : '绑定用户' }}</button>
    </div>
</template>

<script>
export default {
    props: {
        model: Object
    },

    computed: {
        deviceStatusStr() {
            if (this.model.deviceStatus == DEVICE_BINDING_STATUS_CODE.待回收 && this.model.outTime) {
                if(this.model.outTime > 1){
                    return `逾期${this.model.outTime}天`
                }else {
                    return findKey(DEVICE_BINDING_STATUS_CODE, this.model.deviceStatus)
                }
                
            } else {
                return findKey(DEVICE_BINDING_STATUS_CODE, this.model.deviceStatus)
            }
        }
    },

    methods: {
        placeholderText(text, unit) {
            return placeholderText(text, unit)
        },

        onUserButtonClick() {
            this.$emit("onUserButtonClick")
        }
    }
}
</script>

<style lang="scss" scoped>


.panel-user {
    border-bottom: rem(1) dashed #E6E4E9;
    padding-bottom: rem(20);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: rem(20);

    &-left {
        &-info {
            &-user {
                display: flex;
                align-items: center;

                &-name {
                    font-size: rem(16);
                    font-weight: bold;
                    line-height: rem(22);
                }

                &-age {
                    font-size: rem(16);
                    font-weight: bold;
                    margin-left: rem(10);
                    line-height: rem(22);
                }

                &-device-status {
                    font-size: rem(12);
                    margin-left: rem(15);
                    line-height: rem(16);
                }
            }

            &-device-status {
                font-size: rem(16);
                font-weight: bold;
                line-height: rem(22);
            }
        }

        &-company {
            font-size: rem(12);
            line-height: rem(16);
            margin-top: 10px;
        }
    }

    &-button {
        background-color: #325FAC;
        color: white;
        border-radius: rem(18);
        width: rem(100);
        height: rem(36);
    }
}
</style>
import { render, staticRenderFns } from "./monitor-center-alert-unbind.vue?vue&type=template&id=2989b6b6&scoped=true"
import script from "./monitor-center-alert-unbind.vue?vue&type=script&lang=js"
export * from "./monitor-center-alert-unbind.vue?vue&type=script&lang=js"
import style0 from "./monitor-center-alert-unbind.vue?vue&type=style&index=0&id=2989b6b6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2989b6b6",
  null
  
)

export default component.exports
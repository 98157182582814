<template>
    <div class="warning-bar" :style="{ backgroundColor: levelColor }">
        <p class="warning-bar-message">{{ message }}</p>
        <button @click="onDelete">
            <img class="warning-bar-delete" src="@imgs/monitor-center-waring-bar-delete.png">
        </button>
    </div>
</template>

<script>
export default {
    model: {
        prop: "isShow",
        event: "onIsShowChanged"
    },

    props: {
        isShow: Boolean,
        level: Number,
        message: String
    },

    computed: {
        levelColor() {
            switch (this.level) {
                case DEVICE_WARNING_LEVEL.低:
                    return "#A6DBFF"
                case DEVICE_WARNING_LEVEL.中:
                    return "#F39920"
                case DEVICE_WARNING_LEVEL.高:
                    return "#F45C50"
                default:
                    return ""
            }
        }
    },

    methods: {
        onDelete() {
            this.$emit("onIsShowChanged", false)
        }
    }


}
</script>

<style lang="scss" scoped>


.warning-bar {
    border-radius: rem(10);
    height: rem(40);
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: rem(0) rem(20);
    margin-top: rem(10);

    &-message {
        color: white;
        font-size: rem(12);
    }

    &-delete {
        width: rem(21);
        height: rem(21);
    }
}
</style>
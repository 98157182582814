var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"alert-time-selector"},[_c('p',{staticClass:"alert-time-selector-title"},[_vm._v("监测时段")]),_c('el-time-select',{staticClass:"alert-time-selector-content",attrs:{"placeholder":"起始时间","picker-options":{
        start: '00:00',
        end: '23:45',
        step: '00:15',
    },"append-to-body":false,"popper-options":{ positionFixed: true }},model:{value:(_vm.startTime),callback:function ($$v) {_vm.startTime=$$v},expression:"startTime"}}),_c('p',{staticClass:"alert-time-selector-span"},[_vm._v("-")]),_c('el-time-select',{staticClass:"alert-time-selector-content",attrs:{"placeholder":"结束时间","picker-options":{
        start: '00:00',
        end: '23:45',
        step: '00:15',
    },"append-to-body":false,"popper-options":{ positionFixed: true }},model:{value:(_vm.endTime),callback:function ($$v) {_vm.endTime=$$v},expression:"endTime"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
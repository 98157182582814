<template>
    <div class="device-detail-panel">
        <p class="device-detail-panel-title">设备详情</p>
        <div class="device-detail-panel-container container-top">
            <p class="device-detail-panel-container-sn">{{ placeholderText(model.sn) }}</p>
            <panel-user :model="model" @onUserButtonClick="onUserButtonClick"></panel-user>
            <panel-monitor-info :model="model"></panel-monitor-info>
        </div>
        <div v-for="(item, index) of model.warnMsgList" :key="index">
            <waring-bar v-if="isShowArray[index]" :message="item.warnMsg" :level="item.warnLevel"
                v-model="isShowArray[index]"></waring-bar>
        </div>
        <div class="device-detail-panel-container container-bottom">
            <div class="device-detail-panel-container-device">
                <div class="device-detail-panel-container-device-top">
                    <p class="device-detail-panel-container-device-top-title">设备信息</p>
                    <button class="device-detail-panel-container-device-top-edit" @click="onEditButtonClick">编辑</button>
                </div>
                <panel-monitor :model="model"></panel-monitor>
                <panel-ring :model="model"></panel-ring>
            </div>
        </div>

    </div>
</template>

<script>
import PanelUser from "@c/monitor-center/device-detail-panel/panel-user.vue";
import PanelMonitorInfo from "@c/monitor-center/device-detail-panel/panel-monitor-info.vue";
import PanelMonitor from "@c/monitor-center/device-detail-panel/panel-monitor.vue";
import PanelRing from "@c/monitor-center/device-detail-panel/panel-ring.vue";
import WaringBar from "@c/monitor-center/device-detail-panel/waring-bar.vue";
export default {
    components: {
        PanelUser,
        PanelMonitorInfo,
        PanelMonitor,
        PanelRing,
        WaringBar
    },

    props: {
        model: Object
    },

    data() {
        return {
            DEVICE_WARNING_LEVEL: DEVICE_WARNING_LEVEL,
            isShowArray: []
        }
    },

    mounted() {
        for (const _ of this.model.warnMsgList) {
            this.isShowArray.push(true)
        }
    },

    methods: {
        placeholderText(text, unit) {
            return placeholderText(text, unit)
        },

        onUserButtonClick() {
            if (this.model.trueName) {
                this.$emit("onUnbindButtonClick")
            } else {
                switch (this.model.deviceStatus) {
                    case DEVICE_BINDING_STATUS_CODE.闲置:
                    this.$emit("onBindButtonClick")
                        break;
                    case DEVICE_BINDING_STATUS_CODE.维修:
                        this.$toast.showRed("设备维保中");
                        break;
                    case DEVICE_BINDING_STATUS_CODE.维保:
                        this.$toast.showRed("设备维修中");
                        break;
                    default:
                        break;
                }
            }
        },

        onEditButtonClick() {
            this.$emit("onEditButtonClick")
        }
    },



}
</script>

<style lang="scss" scoped>


.device-detail-panel {
    color: #444C72;
    box-sizing: border-box;
    padding: rem(35) rem(20);

    &-title {
        font-size: rem(24);
        line-height: rem(33);
    }

    &-container {
        box-sizing: border-box;
        padding: rem(15);

        &-sn {
            font-size: rem(20);
            font-weight: bold;
            line-height: rem(28);
        }

        &-device {
            &-top {
                display: flex;
                align-items: center;
                justify-content: space-between;

                &-title {
                    font-size: rem(16);
                    font-weight: bold;
                }

                &-edit {
                    color: #325FAC;
                    font-size: rem(16);
                    font-weight: bold;
                }
            }
        }
    }
}

.container-top {
    margin-top: rem(20);
}

.container-bottom {
    margin-top: rem(10);
}
</style>
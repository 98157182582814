<template>
    <monitor-center-alert v-model="isShowAlertTemp" rightButtonTitle="解绑" @onRightButtonClick="onRightButtonClick">
        <template #top>
            <div class="monitor-center-alert-unbind-content">
                <p class="monitor-center-alert-unbind-content-title">解绑用户</p>
                <p class="monitor-center-alert-unbind-content-info">{{ content }}</p>
            </div>
        </template>
    </monitor-center-alert>
</template>

<script>
import MonitorCenterAlert from "@c/monitor-center/alert/monitor-center-alert.vue";
export default {
    components: {
        MonitorCenterAlert
    },

    model: {
        prop: "isShowAlert",
        event: "onIsShowAlertChanged"
    },

    props: {
        isShowAlert: Boolean,
        model: Object
    },

    data() {
        return {
            isShowAlertTemp: undefined,
            content: `是否将设备与用户解绑？\n解绑前请确认已收到报告！`
        }
    },

    mounted() {
        this.isShowAlertTemp = this.isShowAlert
    },

    watch: {
        isShowAlertTemp(value) {
            this.$emit("onIsShowAlertChanged", value)
        }
    },

    methods: {
        async onRightButtonClick() {
            switch (this.model.deviceStatus) {
                case DEVICE_BINDING_STATUS_CODE.已借出: //终止后回收
                    try {
                        const stopParams = { id: this.model.id }
                        await this.$api.stopDevice(stopParams)
                        const recycleParams = { sn: this.model.sn, detectionEquipmentStatus: DEVICE_RECYCLE_STATUS_CODE.完好 }
                        await this.$api.deviceRecycle(recycleParams)
                        this.$emit("onUnbindComplete")
                    } catch (error) {
                        this.$emit("onUnbindComplete", error)
                    }
                    break
                case DEVICE_BINDING_STATUS_CODE.待回收: //直接回收
                    try {
                        const recycleParams = { sn: this.model.sn, detectionEquipmentStatus: DEVICE_RECYCLE_STATUS_CODE.完好 }
                        await this.$api.deviceRecycle(recycleParams)
                        this.$emit("onUnbindComplete")
                    } catch (error) {
                        this.$emit("onUnbindComplete", error)
                    }
                    break;
                default:
                    break;
            }

        }
    }
}
</script>

<style lang="scss" scoped>


.monitor-center-alert-unbind-content {

    display: flex;
    flex-direction: column;
    align-items: center;

    &-title {
        color: #325FAC;
        font-size: rem(25);
        line-height: rem(36);
        margin-top: rem(20);
    }

    &-info {
        color: #444C72;
        font-size: rem(14);
        line-height: rem(21);
        white-space: pre-wrap;
        margin-top: rem(40);
        margin-bottom: rem(67);

    }
}
</style>
<template>
    <div class="key-value-unit">
        <p>{{ title }}：</p>
        <p>{{ value }}</p>
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        value: String,
    }
}
</script>

<style lang="scss" scoped>


.key-value-unit {
    color: #444C72;
    font-size: rem(12);
    line-height: rem(17);
    display: flex;
    align-items: center;
}
</style>
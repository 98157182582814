<template>
    <monitor-center-alert v-model="isShowAlertTemp" rightButtonTitle="绑定" @onRightButtonClick="onRightButtonClick">
        <template #top>
            <div class="monitor-center-alert-bind-content">
                <div class="monitor-center-alert-bind-content-top">
                    <p class="monitor-center-alert-bind-content-top-sn">{{ placeholderText(model.sn) }}</p>
                    <p class="monitor-center-alert-bind-content-top-hospital">{{ placeholderText(model.hospitalName) }}</p>
                </div>
                <div class="monitor-center-alert-bind-content-selector-container">
                    <alert-paging-selector class="monitor-center-alert-bind-content-selector-container-item" title="绑定用户"
                        :hospitalID="model.hospitalId" v-model="selectedUid"></alert-paging-selector>
                    <alert-time-selector class="monitor-center-alert-bind-content-selector-container-item"
                        v-model="monitorDuration"></alert-time-selector>
                    <alert-selector title="使用时长" :dataArray="dataArray" v-model="period"></alert-selector>
                </div>
            </div>
        </template>
    </monitor-center-alert>
</template>

<script>
import MonitorCenterAlert from "@c/monitor-center/alert/monitor-center-alert.vue";
import AlertPagingSelector from "@c/monitor-center/alert/alert-selector/alert-paging-selector.vue";
import AlertSelector from "@c/monitor-center/alert/alert-selector/alert-selector.vue";
import AlertTimeSelector from "@c/monitor-center/alert/alert-selector/alert-time-selector/alert-time-selector.vue";
import { getKeyValueArray } from "@js/object-tool.js";
import { computeDurationStr } from "@c/monitor-center/alert/alert-selector/alert-time-selector/alert-time-selector-tool";
export default {
    components: {
        MonitorCenterAlert,
        AlertPagingSelector,
        AlertSelector,
        AlertTimeSelector
    },

    model: {
        prop: "isShowAlert",
        event: "onIsShowAlertChanged"
    },

    props: {
        isShowAlert: Boolean,
        model: Object
    },

    data() {
        return {
            isShowAlertTemp: undefined,
            selectedUid: undefined,
            dataArray: getKeyValueArray(DEVICE_USE_PERIOD),
            period: undefined,
            monitorDuration: undefined
        }
    },

    mounted() {
        this.isShowAlertTemp = this.isShowAlert
        //selectedUser
        // this.selectedUser = this.model.trueName
        //period
        this.period = this.model.frequency
        //monitorDuration
        const rawDurationStr = this.model.monitor
        const durationStr = rawDurationStr.replace(/\s/g, '')
        if (durationStr) {
            const durationArray = durationStr.split("-")
            const monitorDuration = {
                startTime: durationArray[0],
                endTime: durationArray[1]
            }
            this.monitorDuration = monitorDuration
        } else {
            this.monitorDuration = {
                startTime: "18:00",
                endTime: "6:00"
            }
        }
    },

    watch: {
        isShowAlertTemp(value) {
            this.$emit("onIsShowAlertChanged", value)
        },
    },

    methods: {
        placeholderText(text, unit) {
            return placeholderText(text, unit)
        },

        async onRightButtonClick() {
            try {
                const params = {
                    "sn": this.model.sn,
                    "hospitalId": this.model.hospitalId,
                    "userId": this.selectedUid,
                    "monitor": computeDurationStr(this.monitorDuration),
                    "frequency": this.period
                }
                await this.$api.deviceBorrow(params)
                this.$emit("onBindComplete")
            } catch (error) {
                this.$emit("onBindComplete", error)
            }
        }
    }
}
</script>

<style lang="scss" scoped>


.monitor-center-alert-bind-content {

    display: flex;
    flex-direction: column;
    align-items: center;

    &-top {
        border-bottom: rem(1) solid #E6E4E9;
        width: 100%;

        &-sn {
            color: #444C72;
            font-size: rem(16);
            line-height: rem(22);
        }

        &-hospital {
            color: #444C72;
            font-size: rem(12);
            line-height: rem(17);
            margin-top: rem(10);
            margin-bottom: rem(20);
        }

    }

    &-selector-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: rem(20);
        margin-bottom: rem(20);

        &-item {
            margin-bottom: rem(20);
        }
    }

}
</style>
<template>
  <div class="query-type-selector">
    <button class="query-type-selector-item" @click="onAllClick">
      <img class="query-type-selector-item-radio" :src="[quertType == 0 ? radioBluePath : radioGrayPath]" />
      <p class="query-type-selector-item-title">全部设备{{ totalCount }}台</p>
    </button>
    <button class="query-type-selector-item right" @click="onOnlineClick">
      <img class="query-type-selector-item-radio" :src="[quertType == 1 ? radioBluePath : radioGrayPath]" />
      <p class="query-type-selector-item-title">在线设备{{ onlineCount }}台</p>
    </button>
    <button class="query-type-selector-item right" @click="onAbnormalClick">
      <img class="query-type-selector-item-radio" :src="[quertType == 2 ? radioBluePath : radioGrayPath]" />
      <p class="query-type-selector-item-title">异常设备{{ abnormalCount }}台</p>
    </button>
  </div>
</template>

<script>
export default {
  model: {
    prop: "quertType",
    event: "onQuertTypeChanged"
  },

  props: {
    quertType: Number,
    totalCount: Number,
    onlineCount: Number,
    abnormalCount: Number
  },

  data: function () {
    return {
      radioBluePath: require("@imgs/radio-blue.png"),
      radioGrayPath: require("@imgs/radio-gray.png"),
    };
  },

  methods: {
    onAllClick() {
      this.$emit("onQuertTypeChanged", MONITOR_CENTER_QUERY_TYPE.全部设备);
    },

    onOnlineClick() {
      this.$emit("onQuertTypeChanged", MONITOR_CENTER_QUERY_TYPE.在线设备);
    },

    onAbnormalClick() {
      this.$emit("onQuertTypeChanged", MONITOR_CENTER_QUERY_TYPE.异常设备);
    }
  },
};
</script>

<style lang="scss" scoped>


.query-type-selector {
  display: flex;
  align-items: center;

  &-item {
    display: flex;
    align-items: center;

    &-radio {
      width: rem(14);
      height: rem(14);
    }

    &-title {
      color: #8e8e92;
      font-size: rem(12);
      margin-left: rem(4);
    }
  }

  .right {
    margin-left: rem(16);
  }
}
</style>
<template>
  <div class="monitor-center">
    <div class="monitor-center-top">
      <keyword-search class="monitor-center-top-keyword-search"
        @onSearchClick="onKeywordSearchSearchClick"></keyword-search>
      <query-type-selector class="monitor-center-top-query-type-selector" v-model="quertType" :totalCount="totalNumber"
        :onlineCount="onlineNumber" :abnormalCount="abnormalNumber"></query-type-selector>
    </div>
    <page-list-container class="monitor-center-bottom" :total="total" :pageSize="pageSize" fixedSize
      :inputCurrentPage="currentPage" @onPageChange="onPageChange">
      <div class="monitor-center-bottom-list">
        <div class="monitor-center-bottom-list-item" v-for="(item, index) of rawDeviceArray" :key="index">
          <cell class="monitor-center-bottom-list-item-content" :model="item" @onCellClick="onCellClick"></cell>
        </div>
      </div>
    </page-list-container>
    <el-drawer :visible.sync="isShowDrawer" :with-header="false" destroy-on-close>
      <device-detail-panel :model="selectedDeviceModel" @onUnbindButtonClick="onUnbindButtonClick"
        @onBindButtonClick="onBindButtonClick" @onEditButtonClick="onEditButtonClick"></device-detail-panel>
    </el-drawer>
    <monitor-center-alert-bind v-if="isShowAlertBind" v-model="isShowAlertBind" :model="selectedDeviceModel"
      @onBindComplete="onBindComplete"></monitor-center-alert-bind>
    <monitor-center-alert-unbind v-if="isShowAlertUnbind" v-model="isShowAlertUnbind" :model="selectedDeviceModel"
      @onUnbindComplete="onUnbindComplete"></monitor-center-alert-unbind>
    <monitor-center-alert-edit v-if="isShowAlertEdit" v-model="isShowAlertEdit" :model="selectedDeviceModel"
      @onEditComplete="onEditComplete"></monitor-center-alert-edit>
  </div>
</template>

<script>
import KeywordSearch from "@c/monitor-center/keyword-search.vue";
import QueryTypeSelector from "@c/monitor-center/query-type-selector.vue";
import PageListContainer from "@c/operation-log/common/paging-list/page-list-container.vue";
import Cell from "@c/monitor-center/cell.vue";
import DeviceDetailPanel from "@c/monitor-center/device-detail-panel/device-detail-panel.vue";
import MonitorCenterAlertBind from "@c/monitor-center/alert/monitor-center-alert-bind.vue";
import MonitorCenterAlertUnbind from "@c/monitor-center/alert/monitor-center-alert-unbind.vue";
import MonitorCenterAlertEdit from "@c/monitor-center/alert/monitor-center-alert-edit.vue";
export default {
  components: {
    KeywordSearch,
    QueryTypeSelector,
    PageListContainer,
    Cell,
    DeviceDetailPanel,
    MonitorCenterAlertBind,
    MonitorCenterAlertUnbind,
    MonitorCenterAlertEdit
  },

  data: function () {
    return {
      //quertTypeSelector
      quertType: MONITOR_CENTER_QUERY_TYPE.全部设备,
      totalNumber: 0, //全部设备数
      onlineNumber: 0, //在线设备数
      abnormalNumber: 0, //异常设备数
      //deviceList
      rawDeviceArray: [],
      total: 0, // 显示总条数
      pageSize: undefined, // 每页显示条数
      currentPage: 1, // 当前的页数
      //drawer
      isShowDrawer: false,
      selectedDeviceID: undefined,
      selectedDeviceModel: undefined,
      //alert
      isShowAlertBind: false,
      isShowAlertUnbind: false,
      isShowAlertEdit: false
    };
  },

  computed: {
    hospitalID: function () {
      return this.$store.state.selectedHospital.hospitalid;
    },
  },

  watch: {
    hospitalID() {
      this.currentPage = 1;
      this.deviceListRequest();
    },

    quertType(value) {
      this.currentPage = 1;
      this.quertType = value;
      this.deviceListRequest();
    }
  },

  mounted() {
    const rowItemCount = Math.floor((window.innerWidth - 296) / 190)
    if (rowItemCount) {
      this.pageSize = 4 * rowItemCount
      this.deviceListRequest();
    } else {
      this.pageSize = 20
      this.deviceListRequest();
    }
  },

  methods: {
    onKeywordSearchSearchClick(keyword) {
      this.currentPage = 1;
      this.keyword = keyword;
      this.deviceListRequest();
    },

    //分页
    onPageChange(currentPage) {
      this.currentPage = currentPage;
      this.deviceListRequest();
    },

    // onSizeChange(size) {
    //   this.pageSize = size
    //   this.currentPage = 1
    //   this.deviceListRequest();
    // },

    async deviceListRequest() {
      try {
        const params = {
          "keyWord": this.keyword,
          "status": this.quertType,
          "pageNum": this.currentPage,
          "hospitalId": this.hospitalID,
          "pageSize": this.pageSize,
        }
        const response = await this.$api.getMoniterCenterData(params)
        this.rawDeviceArray = response.list.list;
        this.total = response.list.total;
        this.totalNumber = response.totalNum;
        this.onlineNumber = response.onlineNum;
        this.abnormalNumber = response.abnormalNum
      } catch (error) {
        this.rawDeviceArray = []
        this.$toast.showRed(error);
      }
    },

    //cell
    onCellClick(model) {
      this.selectedDeviceID = model.id
      this.deviceDetailRequest()
    },

    async deviceDetailRequest() {
      try {
        const params = { "id": this.selectedDeviceID }
        const response = await this.$api.getMonitorCenterDeviceDetail(params)

        if (response.online == false) {
          response.bloodOxygen = '';
          response.breathRate = '';
          response.heartRate = '';
          response.networkType = null;
          response.deviceConnectStatus = 1;
          response.connectStatus = null;
          response.battery = 0;
          response.powerStatus = null;
        } else {
          if (response.deviceConnectStatus == 1) {
            response.connectStatus = null;
            response.battery = 0;
            response.powerStatus = null;
          }
        }

        this.selectedDeviceModel = response
        this.isShowDrawer = true
      } catch (error) {
        this.$toast.showRed(error);
      }
    },

    //drawer
    onUnbindButtonClick() {
      this.isShowAlertUnbind = true
    },

    onBindButtonClick() {
      this.isShowAlertBind = true
    },

    onEditButtonClick() {
      this.isShowAlertEdit = true
    },

    //bindAlert
    onBindComplete(error) {
      if (error) {
        this.$toast.showRed(error)
      } else {
        this.$toast.showGreen("设备绑定成功")
        this.deviceListRequest()
        this.deviceDetailRequest()
        this.isShowAlertBind = false
      }
    },

    //unbindAlert
    onUnbindComplete(error) {
      if (error) {
        this.$toast.showRed(error)
      } else {
        this.$toast.showGreen("设备解绑成功")
        this.deviceListRequest()
        this.deviceDetailRequest()
        this.isShowAlertUnbind = false
      }
    },

    //editAlert
    onEditComplete(error) {
      if (error) {
        this.$toast.showRed(error)
      } else {
        this.$toast.showGreen("设备编辑成功")
        this.deviceListRequest()
        this.deviceDetailRequest()
        this.isShowAlertEdit = false
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.monitor-center {
  width: 100%;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  &-top {
    width: 100%;
    height: rem(32);
    flex-shrink: 0;

    &-keyword-search {
      width: rem(320);
      height: 100%;
      float: left;
    }

    &-query-type-selector {
      height: 100%;
      margin-left: rem(13);
      float: left;
    }
  }

  &-bottom {
    background-color: #f0f2f5 !important;
    margin-top: rem(7);
    width: 100%;

    &-list {
      width: 100%;
      overflow-x: scroll;
      display: flex;
      flex-wrap: wrap;
      overflow-x: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      &-item {
        width: 190px;
        height: 170px;
        box-sizing: border-box;
        padding-top: rem(10);
        padding-right: rem(10);
        display: flex;
        align-items: center;
        justify-content: center;

        &-content {
          background-color: white;
          border-radius: rem(4);

        }
      }
    }

    &-empty-info {
      color: rgba($color: black, $alpha: 0.85);
      font-size: rem(14);
      width: 100%;
      margin-top: rem(100);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-pagination {
      width: 100%;
      height: rem(90);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
<template>
    <div class="alert-selector">
        <p class="alert-selector-title">{{title}}</p>
        <el-select class="alert-selector-content" v-model="value" placeholder="请选择" :popper-append-to-body="false">
            <el-option v-for="item in dataArray" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
        </el-select>
    </div>
</template>

<script>
export default {
    model: {
        prop: "initValue",
        event: "onInitValueChanged"
    },

    props: {
        title:String,
        initValue: Number,
        dataArray: Array,
        modeType:Number,
    },

    mounted(){
      
        this.value = this.modeType
    },
    data() {
        return {
            value: ''
        }
    },

    watch: {
       
        // initValue(value){
            
        //     if(value == null || isNaN(value) || value == ''){
        //         // this.value = 1
        //     }else {
        //         this.value = value
        //     }
           
        // },

        value(value) {
           
            this.$emit("onInitValueChanged", value)
            
        }
    }
}
</script>

<style lang="scss" scoped>


.alert-selector {
    background-color: #F6F6F6;
    box-sizing: border-box;
    width: rem(260);
    height: rem(44);
    padding-left: rem(10);
    border-radius: rem(6);

    display: flex;
    align-items: center;

    &-title {
        color: #444C72;
        font-size: rem(14);
        margin-right: rem(15);
    }

    &-content {
        margin-top: rem(2);
        width: rem(172);

        /deep/.el-input__inner {
            background-color: #F6F6F6;
            border-color: transparent !important;
            width: 100%;
        }

        /deep/.el-select__caret {
            color: #444C72
        }

        /deep/.el-select-dropdown el-popper {
            @include z-index-max;
        }

        /deep/.el-select-dropdown__item {
            font-size: rem(14);
            box-sizing: border-box;
            padding-left: rem(20);
        }

        /deep/.el-select-dropdown__item.selected {
            color: #325FAC;
        }
    }
}
</style>
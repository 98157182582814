<template>
    <monitor-center-alert v-model="isShowAlertTemp" rightButtonTitle="确定" @onRightButtonClick="onRightButtonClick">
        <template #top>
            <div class="monitor-center-alert-edit-content">
                <p class="monitor-center-alert-edit-content-title">编辑设备</p>
                <div class="monitor-center-alert-edit-content-selector-container">
                    <alert-selector class="monitor-center-alert-edit-content-selector-container-mode" title="工作模式" :dataArray="dataArray"
                        v-model="monitorMode" :modeType="model.modeType"></alert-selector>
                    <alert-time-selector v-model="monitorDuration"></alert-time-selector>
                </div>
            </div>
        </template>
    </monitor-center-alert>
</template>

<script>
import MonitorCenterAlert from "@c/monitor-center/alert/monitor-center-alert.vue";
import AlertSelector from "@c/monitor-center/alert/alert-selector/alert-selector.vue";
import AlertTimeSelector from "@c/monitor-center/alert/alert-selector/alert-time-selector/alert-time-selector.vue";
import { getKeyValueArray } from "@js/object-tool.js";
import { computeDurationStr } from "@c/monitor-center/alert/alert-selector/alert-time-selector/alert-time-selector-tool";
export default {
    components: {
        MonitorCenterAlert,
        AlertSelector,
        AlertTimeSelector
    },

    model: {
        prop: "isShowAlert",
        event: "onIsShowAlertChanged"
    },

    props: {
        isShowAlert: Boolean,
        model: Object
    },

    data() {
        return {
            isShowAlertTemp: undefined,
            dataArray: getKeyValueArray(DEVICE_MONITOR_MODE),
            monitorMode: undefined,
            monitorDuration: undefined,
            modeType:0,
        }
    },

    mounted() {
       
        this.isShowAlertTemp = this.isShowAlert
        //monitorMode
        this.monitorMode = this.model.modeType
  
        //monitorDuration
        const rawDurationStr = this.model.monitor
        const durationStr = rawDurationStr.replace(/\s/g, '')
        if (durationStr) {
            const durationArray = durationStr.split("-")
            const monitorDuration = {
                startTime: durationArray[0],
                endTime: durationArray[1]
            }
            this.monitorDuration = monitorDuration
        } else {
            this.monitorDuration = {
                startTime: "18:00",
                endTime: "6:00"
            }
        }
    },

    watch: {
        isShowAlertTemp(value) {
            this.$emit("onIsShowAlertChanged", value)
        },
    },

    methods: {
        async onRightButtonClick() {
            try {
                const params = {
                    id: this.model.id,
                    modeType: this.monitorMode,
                    monitor: computeDurationStr(this.monitorDuration),
                    deviceEditType: 1,
                }
                await this.$api.editDevice(params)
                this.$emit("onEditComplete")
            } catch (error) {
                this.$emit("onEditComplete", error)
            }
        }
    }
}
</script>

<style lang="scss" scoped>


.monitor-center-alert-edit-content {

    display: flex;
    flex-direction: column;
    align-items: center;

    &-title {
        color: #325FAC;
        font-size: rem(25);
        line-height: rem(36);
        margin-top: rem(20);
    }

    &-selector-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: rem(40);
        margin-bottom: rem(47);

        &-mode {
            margin-bottom: rem(20);
        }
    }

}
</style>